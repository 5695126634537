import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

const Container = styled(`ul`)`
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  opacity: 0;
  z-index: 2;
  position: absolute;
  background: #ededed;
  width: 100%;
  list-style: none;
  top: -1000px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 300;

  ${({ open }) => open && "opacity: 1;"}
  ${({ open }) => open && "top: 0;"}

  li {
    padding: 1rem 0;
    border-top: 4px solid #fff;
    width: 100%;
    text-align: center;
  }

  @media only screen and (min-width: 1200px) {
    display: none;
  }
`

const Menu = ({ menuOpen, setMenuOpen }) => (
  <Container
    className="menu"
    open={menuOpen}
    onClick={() => setMenuOpen(!menuOpen)}
  >
    <li>
      <Link to="/">
        <div style={{ width: "100%", height: "100%" }}>Home</div>
      </Link>
    </li>
    <li>
      <Link to="/our-values">
        <div style={{ width: "100%", height: "100%" }}>Our Values</div>
      </Link>
    </li>
    <li>
      <Link to="/meet-the-team">
        <div style={{ width: "100%", height: "100%" }}>Meet The Team</div>
      </Link>
    </li>
    <li>
      <Link to="/testimonials">
        <div style={{ width: "100%", height: "100%" }}>Testimonials</div>
      </Link>
    </li>
    <li>
      <Link to="/our-services">
        <div style={{ width: "100%", height: "100%" }}>Our Services</div>
      </Link>
    </li>
    <li>
      <Link to="/gallery">
        <div style={{ width: "100%", height: "100%" }}>Gallery</div>
      </Link>
    </li>
    <li>
      <Link to="/video-gallery">
        <div style={{ width: "100%", height: "100%" }}>Videos</div>
      </Link>
    </li>
    <li>
      <Link to="/contact">
        <div style={{ width: "100%", height: "100%" }}>Contact</div>
      </Link>
    </li>
  </Container>
)

Menu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
}

export default Menu
