import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Header from "./header"
import styled from "styled-components"
import 'bootstrap/dist/css/bootstrap.min.css';
import "./layout.css"
import NavLinks from "./NavLinks"
import Menu from "./Menu"
import Logo from "../images/hg-logo.svg"
import Twitter from "../images/twitter-logo.svg"
import Facebook from "../images/facebook-logo.svg"
import Instagram from "../images/instagram-logo.svg"

const Main = styled(`main`)`
  align-items: center;
  flex: 1;
  flex-flow: column nowrap;
  width: 100%;
  position: relative;
`

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="wrapper">
        <Header
          setMenuOpen={setMenuOpen}
          menuOpen={menuOpen}
          siteTitle={data.site.siteMetadata.title}
        />
        <Main menuOpen={menuOpen}>
          <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          {children}
        </Main>
        <div style={{ boxShadow: `0px -3px 10px rgba(0, 0, 0, 0.1)` }}>
          <footer className="footer">
            <Link to="/">
                  <img src={Logo} className="logo-nav" alt="Henry Gatewood Construction" />
            </Link>
            <div>© {new Date().getFullYear()} Henry Gatewood Construction</div>
            <NavLinks className="foot-links" />
            <div>
              <a href="https://twitter.com/henry_gatewood" target="_blank" rel="noreferrer">
                  <img src={Twitter} className="social-nav" alt="Twitter" />
              </a>
              <a href="https://www.facebook.com/henry.gatewood.3/" target="_blank" rel="noreferrer">
                  <img src={Facebook} className="social-nav" alt="Facebook" />
              </a>
              <a href="https://www.instagram.com/henrygatewoodconstruction_/" target="_blank" rel="noreferrer">
                  <img src={Instagram} className="social-nav" alt="Instagram" />
              </a>
            </div>
            <div>Website made by <a href="https://blinkwing.com/" target="_blank" rel="noreferrer">Blinkwing</a></div>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
