import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"

const Li = styled.li`
  line-height: 1.5rem;
`

const NavLinks = props => (
  <ul
    className={props.className}
    style={{
      borderStyle: "solid",
      display: "flex",
      flexWrap: "wrap",
      borderRight: "none",
      borderLeft: "none",
    }}
  >
    <Li>
      <Link to="/">Home</Link>
    </Li>
    <Li>
      <Link to="/our-values">Our Values</Link>
    </Li>
    <Li>
      <Link to="/meet-the-team">Meet The Team</Link>
    </Li>
    <Li>
      <Link to="/testimonials">Testimonials</Link>
    </Li>
    <Li>
      <Link to="/our-services">Our Services</Link>
    </Li>
    <Li>
      <Link to="/gallery">Gallery</Link>
    </Li>
    <Li>
      <Link to="/video-gallery">Videos</Link>
    </Li>
    <Li>
      <Link to="/contact">Contact</Link>
    </Li>
  </ul>
)

NavLinks.propTypes = {
  className: PropTypes.node.isRequired,
}

export default NavLinks
