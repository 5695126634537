import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Logo from "../images/hg-logo-no-motto.svg"
import Nav from "./Nav"
import MenuButton from "./MenuButton"

const Container = styled(`header`)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  padding: 0.5rem;
  z-index: 10;

  .logo-nav {
    max-height: 175px;
    max-width: 100%;
  }

  .nav {
    display: none;
  }

  .menuButton {
    display: flex;
  }

  @media only screen and (min-width: 720px) {
    .menuButton {
      display: none;
    }

    .nav {
      display: flex;
    }
  }

  @media only screen and (max-width: 719px) {
    justify-content: space-between;
    flex-flow: row nowrap;

    .logo-nav {
      max-height: 100px;
      max-width: 100%;
      padding-left: 1rem;
    }

    .menuButton {
      padding-right: 1rem;
    }

    .social-nav {
      max-height: 50px;
      max-width: 100%;
      padding: 0 0.5rem;
    }

    nav {
      display: none;
    }
  }
`

const Header = ({ setMenuOpen, menuOpen }) => (
  <div style={{ boxShadow: `0px 3px 10px rgba(0, 0, 0, 0.1)` }}>
    <Container>
      <div style={{ paddingBottom: "1rem" }}>
        <Link to="/">
          <img
            src={Logo}
            className="logo-nav"
            alt="Henry Gatewood Construction"
          />
        </Link>
      </div>
      <div>
        <Nav />
      </div>
      <MenuButton
        menuOpen={menuOpen}
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      />
    </Container>
  </div>
)

Header.propTypes = {
  setMenuOpen: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
}

export default Header
