import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Container = styled(`ul`)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;

  li {
    display: flex;
    align-items: center;
    padding: 0px 25px;
    font-size: 1.5rem;

    > * {
      font-size: 1.5rem;
    }
  }
`

const Nav = () => (
  <nav>
    <Container className="nav">
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <div className="dropdown">
          <div className="dropdown-header">
            <span className="dropdown-header-text">About Us</span>
            <div className="arrow down"></div>
          </div>
          <div className="dropdown-content">
            <Link to="/meet-the-team">Meet The Team</Link>
            <Link to="/testimonials">Testimonials</Link>
            <Link to="/our-values">Our Values</Link>
            <Link to="/our-services">Our Services</Link>
          </div>
        </div>
      </li>
      <li>
        <div className="dropdown">
          <div className="dropdown-header">
            <span className="dropdown-header-text">Portfolio</span>
            <div className="arrow down" />
          </div>
          <div className="dropdown-content">
            <Link to="/gallery">Gallery</Link>
            <Link to="/video-gallery">Videos</Link>
          </div>
        </div>
      </li>
      <li>
        <Link to="/contact">Contact</Link>
      </li>
    </Container>
    <p
      style={{
        color: "#0d88b7",
        textAlign: "center",
        paddingTop: "1.25rem",
        fontStyle: "italic",
        fontWeight: "bold",
        fontSize: "1.5em",
      }}
    >
      Building Dreams One Home at a Time
    </p>
  </nav>
)

export default Nav
